<template>
    <div>Hola '{{ msg }}'</div>
</template>

<script>
export default {
    props: {
        msg: String,
    },
};
</script>
